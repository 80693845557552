import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import img from './about.svg'
import { contx } from '../../../App';
import Button from 'react-bootstrap/esm/Button';

const Secabout = () => {


    const { lang } = React.useContext(contx);

    const data = lang === "en" ?  en:ar 


    return (
        <div>
            <Container dir={lang === "en" ? "ltr" : "rtl"}>
                <Row className="align-items-center p-5">
                    <Col lg={6}>
                        <img src={img} style={{width:'100%'}} alt="logo" />
                    </Col>
                    <Col lg={6} >
                        <p style={{textAlign:'justify',lineHeight:'1.7'}} >{data.p}</p>
                       <Button style={{backgroundColor:'#005247',color:'white',border:'1px solid #005247'}}>{data.btn}</Button>
                    </Col>
                </Row>
                
            </Container>
        </div>
    );
}

export default Secabout;


const ar ={p: `
شركة تطوير الأفكار هي شركة رائدة في مجال التدريب الخاص بالموارد البشرية، تأسست عام 2023. وهي مسجلة بصورة قانونية و تمتلك الشركة الرقم الضريبي  ورقم الضمان .
وتقدم الشركة برامج تدريبية مخصصة لتلبية احتياجات الأفراد والشركات من التدريب المهني والاكاديمي، بهدف تطوير المهارات والكفاءات اللازمة لتحسين أدائهم في مجالات محددة.
تعتمد الشركة على مدربين محترفين ومحتوى تدريبي محدد لضمان تحقيق أهداف التعلم والتطوير.
وتولي اهتمامًا خاصًا بنقل المعرفة وتحسين الأداء الوظيفي للفرد أو الفريق. كما تقدم تدريبًا في مجموعة واسعة من المجالات مثل التنمية الشخصية وإدارة المشاريع وتطوير المهارات الفنية.
وتركزعلى تخصيص برامج تدريب متقدمة مع احتياجات العملاء، سواء كان ذلك في مجالات الأعمال أو التكنولوجيا أو القيادة أو أي مجال آخر يتطلب تطويرًا مهنيًا.
`,

btn:'أقرأ المزيد',

}

const en = {p:`
Company Overview Ideas Development Company is a leading firm in the field of human resources training, founded in 2023. It is legally registered under decision number 
The company has tax ID  and guarantee number 

The company provides customized training programs to meet the needs of individuals and companies in professional and academic training, aiming to develop the necessary skills and competencies to improve performance in specific fields

The company relies on professional trainers and specific training content to ensure the achievement of learning and development goals

It focuses on knowledge transfer and improving individual or team performance, offering training in a wide range of areas such as personal development, project management, and technical skills development.

The company emphasizes tailoring advanced training programs to meet the professional development needs of clients in areas such as business, technology, leadership, or any other field requiring professional development.

`,

btn:'Read More',


}


