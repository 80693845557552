import React from 'react';
import logo from './navebar/logo.svg';

const Looder = () => {
    return (
        <div style={{ width: '100%' ,height:'100vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <img src={logo} width={'60%'} className='img-loder' alt="" />
        </div>
    );
}

export default Looder;
