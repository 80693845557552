import React from 'react';
import img from './5.png'
import Container from 'react-bootstrap/esm/Container';
import { contx } from '../../../App';
import img1 from './abouticon/1.svg'
import img2 from './abouticon/2.svg'
import img3 from './abouticon/3.svg'
import img4 from './abouticon/4.svg'
import img5 from './abouticon/5.svg'
import img6 from './abouticon/6.svg'
import img7 from './abouticon/7.svg'
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import img12 from './12.png'


const About = () => {
    const { lang } = React.useContext(contx);

    const data = lang === "en" ?  en:ar
    const imgsrc = [img1, img2, img3, img4, img5, img6, img7]

    return (
        <div style={{ width: '100%' }} dir={lang === "en" ? "ltr" : "rtl"}>

            <img src={img} style={{ width: '100%' }} alt="" />


            <Container className='py-5'>

                <h4 style={{ textAlign: 'start', color: '#005247', marginTop: '3vw' }} >{data.p}</h4>


                {
                    data.list.map((item, index) => {

                        return (
                            <div key={index} style={{ textAlign: 'start', marginTop: '2vw',display:'flex',alignItems:'center' }} >
                                <img src={imgsrc[index]} style={{ width: '50px' }} alt="" />

                                <p className='mx-4' style={{ textAlign: 'start',fontSize:'18px' }} >{item}</p>


                            </div>

                        )
                    })
                }




            </Container>




            <div className='p-5' style={{ width: '100%' , backgroundColor: '#F3F4F6'}}>
                           <Container>
                            <Row>
                                <Col md={6}>
                                    <h1 style={{ textAlign: 'start', color: '#0B5846' }}>{data.vis}</h1>
                                    <p style={{ textAlign: 'start', fontSize: '18px' }}>{data.visp}</p>
                                    {
                                        data.vislist.map((item, index) => {

                                            return (
                                                <p key={index} style={{ textAlign: 'start' }} >{'* '}{item}</p>
                                            )
                                        })
                                    }
                                </Col>
                                <Col md={6}>
                                    <img src={img12} style={{ width: '100%' }} alt="" />
                                </Col>
                            </Row>
                           </Container>
            </div>



            
        </div>
    );
}

export default About;


const en = {
    p :`Ideas Development Company aims to create a different type of training that helps trainees understand the Iraqi job market and its needs. This is achieved through the following objectives:`,
    list : [
        `Meeting job market needs by providing necessary skills and knowledge aligned with changing job market requirements.`,
        `Developing practical work skills to help trainees efficiently adapt to the work environment.`,
        `Improving employment opportunities through deep and practical training, increasing the chances of obtaining a job.`,
        `Promoting continuous learning by emphasizing its importance and providing ongoing training programs to keep up with technological and economic developments`,
        `Enhancing innovation and creativity by encouraging trainees to think creatively and develop innovative skills to solve professional challenges.`,
        `Improving company performance by providing well-trained employees, helping companies enhance their performance and productivity.`,
        `Promoting personal development by focusing on enhancing trainees' personal development, not just work skills, leading to comprehensive individual growth.`,
        
    ],

    vis:`Our Vision`,
    visp:`Ideas Development Company aims to be a leading professional training company in Iraq, providing high-quality training programs that meet the needs of individuals and companies by:`,
    vislist:[
        
        `Adopting the latest training methods and technologies.`,
        `Collaborating with educational institutions and professional bodies to exchange experiences and knowledge.`,
        
        `Expanding the company's operations throughout Iraq.`,
    ]
}



const ar = {
    p :`تسعى شركة تطوير الأفكار إلى خلق نوع مختلف من التدريبات التي تساعد المتدرب على فهم سوق العمل العراقي واحتياجاته، وذلك من خلال تحقيق الأهداف التالية:`,
    list : [
        `	تلبية احتياجات سوق العمل: من خلال تزويد المتدرب بالمهارات والمعرفة اللازمة للنجاح في المجالات المهنية المختلفة، بما يتماشى مع متطلبات سوق العمل المتغيرة.`,
        `تطوير مهارات العمل: من خلال تعزيز وتطوير مهارات العمل العملية لدى المتدرب، مما يساعده على التأقلم مع بيئة العمل بكفاءة.`,
        `تحسين فرص التوظيف: من خلال توفير تدريب عملي وعميق، مما يساعد المتدرب على زيادة فرصه في الحصول على وظيفة.`,
        `	تعزيز التعلم المستمر: من خلال تشجيع المتدرب على فهم أهمية التعلم المستمر وتقديم برامج تدريبية مستمرة له لمساعدته على مواكبة التطورات التكنولوجية والاقتصادية.`,
        `	تعزيز االبتكار واإلبداع: من خلال تشجيع المتدرب على التفكير اإلبداعي وتطوير مهارات االبتكار لديه، مما يساعده على الابتكار وتطوير حلول جديدة للتحديات المهنية.`,
        `	تحسين أداء الشركات: من خلال توفير موظفين مدربين بشكل جيد، مما يساعد الشركات على تحسين أدائها وزيادة إنتاجيتها.`,
        `تعزيز التنمية الشخصية: من خلال تسعى الشركة إلى تعزيز التنمية الشخصية للمتدرب، وليس فقط مهارات العمل، مما يؤدي إلى نمو شامل للفرد.`,
        
    ],

    vis:`رؤيتنا`,
    visp:`تسعى شركة تطوير الأفكار إلى أن تكون شركة رائدة في مجال التدريب المهني في العراق، ولتقديم برامج تدريبية عالية الجودة تلبي احتياجات الأفراد والشركات، وذلك من خلال:`,
    vislist:[
        
        `	الاعتماد على أحدث الأساليب والتقنيات التدريبية.`,
        `	التعاون مع المؤسسات التعليمية والهيئات المهنية لتبادل الخبرات والمعرفة.`,
        
        `	التوسع في أعمال الشركة في جميع أنحاء العراق.`,
    ]
}