import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import img from './1.svg'
import arrow from './2.svg'
import { Link } from 'react-router-dom';

const Courses = () => {
    return (
        <div>
            <Container>
                <Row>
                    {
                        [1, 2, 3, 4, 5, 6, 7, 8].map(item => <Col md={6} lg={3} key={item} className='p-4'>
                            <Link to={'/itemdes'} style={{ textDecoration: 'none',color:'black' }} >

                            <div  style={{

                                boxShadow: '0px 0px 31px 0px rgba(0, 0, 0, 0.08)',
                            }}>
                                <img src={img} style={{ width: '100%' }} alt="" />
                                <div style={{
                                    padding: '10px',
                                }} >
                                    <h4 style={{
                                        textAlign: 'start',
                                        margin: '6px 0',
                                    }}>title of course</h4>
                                    <p style={{ textAlign: 'start' }}>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                    </p>

                                    <p style={{ textAlign: 'start' ,color:'#005247'}} >read more

                                        <img style={{ width: '20px' ,
                                    
                                    transform:'translatex(5px)'
                                    }} src={arrow} alt="" />

                                    </p>
                                </div>
                            </div></Link>



                        </Col>)
                    }
                </Row>
            </Container>
        </div>
    );
}

export default Courses;
