import React from 'react';

const Itemdes = () => {
    return (
        <div>
            <h1>Itemdes</h1>
        </div>
    );
}

export default Itemdes;
