
import './App.css';
import Home from './component/page/home/home';
// import bootstrap'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbarpage from './component/navebar/Navbar_page'
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';  
import About from './component/page/about/about';
import Courses from './component/page/courses/courses';
import Itemdes from './component/page/courses/itemdes';
import Servises from './component/page/servises/servises';
import Footer from './component/footer/footer';
import Looder from './component/looder';


export const contx = React.createContext()

function App() {

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {

    setTimeout(() => {

      setLoading(false);
    }, 5000)





    window.scrollTo(0, 0);
  }, []);

  const [lang, setLang] = React.useState("en");


  return (<>

    <div hidden={!loading}>

      <Looder />
    </div>
    <div className="App" hidden={loading}>
      <contx.Provider value={{ lang, setLang }}>

        <BrowserRouter>
          <Navbarpage />
          <Routes>


            <Route path="/" index element={<Home />} />
            <Route path="/about" element={<About/>} />
            <Route path="/Courses" element={<Courses/>} />
            <Route path="/Servises" element={<Servises/>} />
            <Route path="/Courses/:id" element={<Itemdes/>} />

    

            {/* <Route path="/contact" element={<Contact />} />
                <Route path="/about" element={<About />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/partners" element={<Partners />} />
                <Route path="/Careers" element={<Career />} />
 */}



          </Routes>


        <Footer/>


        </BrowserRouter>



      </contx.Provider>
    </div></>
  );
}

export default App;
