import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from './logo.svg'
import { contx } from '../../App';
import React from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, NavLink } from 'react-router-dom';



function BasicExample() {

 

  const classNamenav = ({ isActive, isPending, isTransitioning }) =>
  [
    isPending ? "pending" : "",
    isActive ? "active" : "",
    isTransitioning ? "transitioning" : "",
  ].join(" ")


  const { lang, setLang } = React.useContext(contx);

  const [drlang, setdrlang] = React.useState("English");


  const changeLang = () => {

    if (drlang == "English") {
      setLang("ar")
      setdrlang("عربي")
    }
    else {
      setLang("en")
      setdrlang("English")
    }
  }

 const ennav =['Home','About Us','Contact Us','Courses','Our serveses'];
 const arnav =['الرئيسية','عن الشركة','اتصل بنا','الدورات','من نحن'];

 const navname = lang === "en" ? ennav : arnav;

  return (
    <Navbar dir={
      lang === "en" ? "ltr" : "rtl"}
     sticky="top" collapseOnSelect style={{
      backgroundColor: '#F1EBE3',


    }} expand="lg">
      <Container>
        <Navbar.Brand href="#home"><img src={logo} style={{ width: '100px' }} alt="logo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto " style={{ margin: '0 10px', fontSize: '18px' }}>
            <NavLink style={{textDecoration:'none',color:'#000', }} className={({ isActive }) => isActive ? 'active mx-3' : 'mx-3'} to="/">{navname[0]}</NavLink> 
            <NavLink style={{textDecoration:'none',color:'#000', }} className={({ isActive }) => isActive ? 'active mx-3' : 'mx-3'} to="/about">{navname[1]}</NavLink>
            <NavLink style={{textDecoration:'none',color:'#000', }} className={({ isActive }) => isActive ? 'active mx-3' : 'mx-3'} to="/Courses">{navname[3]}</NavLink>
            <NavLink style={{textDecoration:'none',color:'#000', }} className={({ isActive }) => isActive ? 'active mx-3' : 'mx-3'} to="/Servises">{navname[4]}</NavLink>


          </Nav>


          <NavDropdown
            id="nav-dropdown-dark-example"
            title={drlang}
            menuVariant="dark"
          >
            <NavDropdown.Item onClick={changeLang} >English </NavDropdown.Item>
            <NavDropdown.Item onClick={changeLang} > عربي </NavDropdown.Item>
           
          </NavDropdown>

        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BasicExample;