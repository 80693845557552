import React from 'react';
import logo from './logo.svg'
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import insta from './insta.png'
import phon from './phon.svg'
import loc from './loc.png'
const Footer = () => {
    return (<>
        <div className='p-3' style={{ width: '100%' ,backgroundColor:'#005247',color:'white'}}>

            <Container>
                <Row>
                    <Col md={2}>
                    <img src={logo} width={200} alt="logo" />
                        
                    </Col>
                    <Col md={10} className='m-auto'>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',gap:'55px'}}>

                    <a href="https://www.instagram.com/tatweer.iq?igsh=MXZjcHhycDBqMjRodQ==" style={{color:'white',textDecoration:'none'}}><img src={insta} width={30} alt="logo" /><p>tatweer.iq</p></a>
                    <a href="tel:+9647822000066" style={{color:'white',textDecoration:'none'}}><img src={phon} width={30} alt="logo" /><p>07822000066</p></a>
                    

                    </div>
                      
                           
                    
                       
                        
                    </Col>
                </Row>
            </Container>
            <hr />
              <p className='m-0 p-0' style={{textAlign:'center',color:'#cecece'}}>Copyright © 2024 Tatweer. All rights reserved.</p>
        </div>
        
      
        </>
    );
}

export default Footer;
