import React from 'react';
import img from './1.png';
import {Accordion, Container} from 'react-bootstrap';
import {contx} from '../../../App';

const Servises = () => {

    const { lang } = React.useContext(contx);
    const data = lang === "en" ?  en:ar
    return (
        <div  style={{width:'100%',direction:lang === "en" ? "ltr" : "rtl",textAlign:lang === "en" ? "left" : "right"}} >
            <img src={img} style={{width:'100%'}} alt="logo" />

            <Container className='my-5'>



                <h2 style={{color:'#005247'}}>{data.h1}</h2>

                <ul>
                    {
                        data.list1.map((item, index) => {

                            return (
                                <li key={index}>
                                    {item}
                                </li>
                            )
                        })
                    }
                </ul>

                        <br /><br />

                <h2 style={{color:'#005247'}}>{data.h2}</h2>

                <br /><br />

                <h2 style={{color:'#005247'}}>{data.h3}</h2>


                <h4 style={{color:'#777'}}>{data.p3}</h4>

                <ul>
                    {
                        data.list3.map((item, index) => {
                            return (
                                <li key={index}>
                                    {item}
                                </li>
                            )
                        })
                    }
                </ul>
                <br /><br />


                <h4 style={{color:'#777'}}>{data.stepsheder}</h4>





                <Accordion defaultActiveKey="0" >
      <Accordion.Item eventKey="0">
        <Accordion.Header >{data.steps[0].h}</Accordion.Header>
        <Accordion.Body>

            <ul>
                <li>
                    <h4>{data.steps[0].p.h1}</h4>
                    <ol>
                        {
                            data.steps[0].p.p1.map((item, index) => {

                                return (
                                    <li key={index}>
                                        {item}
                                    </li>
                                )
                            })
                        }
                    </ol>
                </li>
                <li>
                    <h4>{data.steps[0].p.h2}</h4>
                </li>
            </ul>
          
       
        </Accordion.Body>
      </Accordion.Item>
      
      <Accordion.Item eventKey="1">
        <Accordion.Header>{data.steps[1].h}</Accordion.Header>
        <Accordion.Body>
         <ul>
            {
                data.steps[1].p.map((item, index) => {

                    return (
                        <li key={index}>
                            {item}
                        </li>
                    )
                })
            }
         </ul>
        </Accordion.Body>
      </Accordion.Item>
   






    <Accordion.Item eventKey="2">
        <Accordion.Header>{data.steps[2].h}</Accordion.Header>
        <Accordion.Body>
         <ul>
            {
                data.steps[2].p.map((item, index) => {

                    return (
                        <li key={index}>
                            {item}
                        </li>
                    )
                })
            }
         </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>{data.steps[3].h}</Accordion.Header>
        <Accordion.Body>
         {
            data.steps[3].p
            }
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>


            </Container>

            
        </div>
    );
}

export default Servises;


const en = {
    h1:`First : Training in Various Business Sectors`,
    list1:[
        `Digital Marketing`,
        `Human Resources`,
        `Digital Transformation`,
        `Business English Language`,
        `Feasibility Studies`,
        `Excel Formulas`,
        `Private Banking Standard (Standard Number 9)`,
        `Professional Photography`,
        `Accounting`,
        `Financial Analysis`,
        `Real Estate Sales`,
        `Comprehensive Sales`,
       
    ],
    h2:`Second : Providing Consultations and Planning for Companies `,
    h3:`Third : Feasibility Studies`,

    p3:`Studies We analyze market needs to:`,
    list3:[
        `Identify skills and knowledge required for success in the job market.`,
        `Ensure alignment of training programs with modern developments in the industry and technology.`,
        `Design customized training programs to meet the needs of individuals and companies.`,
    ],

    stepsheder:`Steps`,

    steps:[
        {
            h:`Study the job market requirements:`,
            p:{
                h1:`Collect data from various sources, such as`,
                p1:[
                    `government and private employment reports.`,
                    `Analyze workforce market data.`,
                    `Business data market analysis.`,
                ],
                h2:`Analyze industry and technology trends`,
                
            }
        },
        {
            h:`Monitor industry and technology developments`,
           p:[
            `Monitor industry and technology developments`,
            `Identify skills and knowledge required in the future`,
           ]
        },

        {
            h:`Design customized programs:`,
            p:[
                `Based on previous steps' results, design tailored training programs meeting individual and corporate needs`,
                `Training programs include the required skills and knowledge, in addition to practical skills and personal skills.`,
            ],
        },
        {
            h:`Integration of practical training:`,
            p:`Include practical training in programs to help trainees gain practical experience.`,
        },
    ]
    
}





const ar = {
    h1:`أولا : تدريبات بمختلف المجالات الخاصة بقطاع الاعمال`,
    list1:[
        `	التسويق الرقمي`,
        `	الموارد البشرية`,
        `التحول الرقمي`,
        `لغة الاعمال النكليزية`,
        `دراسات الجدوى`,
        `معادلات الاكسل`,
        `المعيار المصرفي الخاص ( معيار رقم 9 )`,
        `التصوير الاحترافي`,
        `محاسبة`,
        `تحليل مالي`,
        `مبيعات عقارية`,
        `مبيعات شاملة `,
       
    ],
    h2:`ثانيا : تقديم الاستشارات والمشورات واعداد الخطط للشركات`,
    h3:`ثالثا : عمل دراسات الجدوى`,

    p3:`نعمل على تحليل احتياجات السوق لغرض:`,
    list3:[
        `	تحديد المهارات والمعرفة المطلوبة للنجاح في سوق العمل.`,
        `	ضمان مواكبة برامج التدريب للتطورات الحديثة في الصناعة والتكنولوجيا.`,
        `	تصميم برامج تدريبية مخصصة تلبي احتياجات الأفراد والشركات.`,
    ],

    stepsheder:`الخطوات`,

    steps:[
        {
            h:`	دراسة متطلبات سوق العمل:`,
            p:{
                h1:`	جمع البيانات من مصادر مختلفة، مثل:`,
                p1:[
                    `تقارير التوظيف الحكومية والخاصة.`,
                    `نتائج الدراسات الاستقصائية التي تستهدف أصحاب العمل والموظفين.`,
                    `تحليل بيانات سوق العمل.`,
                ],
                h2:`تحليل البيانات لتحديد المهارات والمعرفة المطلوبة في مختلف المجالات المهنية.`,
                
            }
        },
        {
            h:`	تحليل اتجاهات الصناعة والتكنولوجيا:`,
           p:[
            `	متابعة التطورات في الصناعة والتكنولوجيا.`,
            `	تحديد المهارات والمعرفة التي ستكون مطلوبة في المستقبل.`,
           ]
        },

        {
            h:`	تصميم برامج مخصصة:`,
            p:[
                `	بناءً على نتائج الخطوات السابقة، تصميم برامج تدريبية مخصصة تلبي احتياجات الأفراد والشركات.`,
                `	تتضمن البرامج التدريبية المهارات والمعارف المطلوبة، بالإضافة إلى المهارات العملية والمهارات الشخصية.`,
            ],
        },
        {
            h:`	دمج التدريب العملي:`,
            p:`	تضمين التدريب العملي في البرامج التدريبية لمساعدة المتدربين على اكتساب الخبرة العملية.`,
        },
    ]
    
}


